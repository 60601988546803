import React from 'react'
import { useHistory } from 'react-router-dom'
export default function Task({ description, url, icon, locked }) {
  const history = useHistory()
  return (
    <div class='component_Task'>
      <div class={`wrapper mb-10 ${locked ? 'wrapper-disabled' : ''}`} onClick={() => !locked && history.push(url)}>
        <i class={icon} />
        <span>{description}</span>
        {!locked && <i class='fas fa-angle-right' />}
        {locked && <i class='fas fa-lock' />}
      </div>
    </div>
  )
}
