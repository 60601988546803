import { icons } from './icons'
const menu = [
  {
    type: 'link',
    icon: 'fa-home',
    title: 'Översikt',
    link: '#!/dashboard',
    active_key: 'dashboard'
  },
  {
    type: 'link',
    icon: 'fa-calendar',
    title: 'Kalender',
    link: '#!/calendar',
    active_key: 'calendar'
  },
  {
    type: 'link',
    icon: 'fa-briefcase',
    title: 'Företag',
    link: '#!/company',
    active_key: 'company'
  },
  {
    type: 'collapsable',
    icon: icons.maintenance,
    title: 'Teknisk förvaltning',
    active_key: 'maintenance',
    children: [
      {
        title: 'Aktiviteter',
        link: '#!/maintenance/overview'
      },
      {
        title: 'Rutiner',
        link: '#!/maintenance/template/overview'
      }
    ]
  },
  {
    type: 'collapsable',
    icon: 'fa-file-invoice',
    title: 'Hyresavier',
    active_key: 'invoicebase',
    children: [
      {
        title: 'Alla',
        link: '#!/invoicebase/overview'
      },
      {
        title: 'Attestering',
        link: '#!/invoicebase/status/review',
        badge: {
          key: 'invoicebase_review'
        }
      },
      {
        title: 'Redo att skickas',
        link: '#!/invoicebase/status/ready',
        badge: {
          key: 'invoicebase_ready'
        }
      },
      {
        title: 'Redigeras',
        link: '#!/invoicebase/status/edit'
      },
      {
        title: 'Skickade',
        link: '#!/invoicebase/status/sent'
      },
      {
        title: 'Kräver åtgärd',
        link: '#!/invoicebase/status/error',
        badge: {
          key: 'invoicebase_error',
          color: 'danger'
        }
      },
      {
        title: 'Förfallna',
        link: '#!/invoicebase/status/overdue',
        badge: {
          key: 'invoicebase_overdue'
        }
      }
    ]
  },
  {
    type: 'collapsable',
    icon: 'fa-key',
    title: 'Uthyrningar',
    active_key: 'lease',
    children: [
      {
        title: 'Alla',
        link: '#!/lease/overview'
      },
      {
        title: 'Aktiva',
        link: '#!/lease/filter/active'
      },
      {
        title: 'Kommande',
        link: '#!/lease/filter/planned'
      },
      {
        title: 'Upphörande',
        link: '#!/lease/filter/ending',
        badge: {
          key: 'lease_ending',
          color: 'danger'
        }
      }
    ]
  },
  {
    type: 'collapsable',
    icon: 'fa-layer-group',
    title: 'Objekt',
    active_key: 'entity',
    children: [
      {
        title: 'Alla',
        link: '#!/entity/overview'
      },
      {
        title: 'Fastigheter',
        link: '#!/entity/type/estates'
      },
      {
        title: 'Lägenheter',
        link: '#!/entity/type/apartments'
      },
      {
        title: 'Lokaler',
        link: '#!/entity/type/premises'
      },
      {
        title: 'Parkeringar',
        link: '#!/entity/type/parkings'
      },
      {
        title: 'Vakanser',
        link: '#!/entity/type/vacancies'
      }
    ]
  },
  {
    type: 'collapsable',
    icon: 'fa-envelope-open-text',
    title: 'Ärenden',
    active_key: 'ticket',
    children: [
      {
        title: 'Alla',
        link: '#!/ticket/overview'
      },
      {
        title: 'Pågående',
        link: '#!/ticket/status/open'
      },
      {
        title: 'Nya',
        link: '#!/ticket/status/new',
        badge: {
          key: 'ticket_new',
          color: 'success'
        }
      }
    ]
  },
  {
    type: 'link',
    icon: 'fa-address-book',
    title: 'Hyresgäster',
    link: '#!/tenant/overview',
    active_key: 'tenant/'
  },
  {
    type: 'link',
    icon: 'fa-receipt',
    title: 'Tillägg & Avdrag',
    link: '#!/addon',
    active_key: 'addon'
  },
  {
    type: 'link',
    icon: 'fa-server',
    title: 'Filer',
    link: '#!/file',
    active_key: 'file'
  },
  {
    type: 'collapsable',
    icon: 'fa-cogs',
    title: 'Inställningar',
    active_key: 'setting',
    children: [
      {
        title: 'Användare',
        link: '#!/setting/user'
      },
      {
        title: 'Integrationer',
        link: '#!/setting/integration'
      },
      {
        title: 'Hyresgästportalen',
        link: '#!/setting/tenantportal'
      },
      {
        title: 'Medlemskap',
        link: '#!/setting/membership'
      },
      {
        title: 'Verksamhet',
        link: '#!/setting/billable'
      }
    ]
  },
  {
    type: 'collapsable',
    icon: 'fa-life-ring',
    title: 'Hjälp',
    active_key: 'help',
    children: [
      {
        title: 'Kontakta oss',
        link: '#!/help/contact'
      },
      {
        title: 'FAQ',
        link: '#!/help/faq'
      },
      {
        title: 'Om oss',
        link: '#!/help/about'
      }
    ]
  }
]
export { menu }
