import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { notify } from '@/_helpers'
import { API, ViewHeader, Card, Submit, CheckBox, Input } from '@enkla-gui'
import Modal from 'react-modal'

export default function User() {
  const { register, handleSubmit, getValues, setValue, watch, reset } = useForm()
  const [error, setError] = useState(null)
  const [passwordModal, setPasswordModal] = useState(null)
  const [sending, setSending] = useState(false)
  const [emailModal, setEmailModal] = useState(false)

  const { register: passwordRegister, handleSubmit: passwordSubmit } = useForm()
  const { register: emailRegister, handleSubmit: emailSubmit } = useForm()

  useEffect(() => {
    API.get('api/auth/me').then(user => reset(user))
  }, [])

  const onSubmit = data => {
    setSending(true)
    API.patch('api/auth/me', data)
      .then(res => {
        notify.toast('Ändringar sparade')
        setError(null)
        reset(res.user)
        setSending(false)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }
  const changePassword = data => {
    setSending(true)
    API.post('api/auth/changepassword', data)
      .then(res => {
        notify.toast('Lösenord uppdaterat')
        setError(null)
        setPasswordModal(false)
        setSending(false)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const changeEmail = data => {
    setSending(true)
    API.post('api/emailchange', data)
      .then(res => {
        notify.toast('Begäran av e-postadress skapad, vi har skickat ett mail med en länk för att genomföra ändringen.')
        setError(null)
        setEmailModal(false)
        setSending(false)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'warning')
        setSending(false)
      })
  }

  return (
    <>
      <ViewHeader
        loading={!watch('id')}
        icon='fa-user'
        title='Inställningar'
        subtitle='Användare'
        badges={[
          {
            bg: getValues('email_verified_at') === null ? 'warning' : 'success',
            text: getValues('email_verified_at') === null ? 'E-post Ej Verifierad' : 'E-post Verifierad',
            icon: 'fas fa-certificate'
          }
        ]}
        buttons={[
          { icon: 'fa-envelope', title: 'Byt e-postadress', callback: () => setEmailModal(true) },
          { icon: 'fa-lock', title: 'Byt lösenord', callback: () => setPasswordModal(true) }
        ]}
      />
      <Card
        loading={!watch('id')}
        buttons={{
          footer: [<Submit onSubmit={handleSubmit(onSubmit)} text='Spara' sending={sending} />]
        }}>
        <div class='row mb-3'>
          <Input controls={{ register }} name='name' options={{ required: true }} label='Namn' error={error} />
          <Input controls={{ register }} name='email' options={{ required: true }} disabled label='E-post' error={error} />
        </div>
        <div class='row'>
          <div class='col mb-3'>
            <label>E-post notiser för nya ärenden</label>
            <br />
            <CheckBox controls={{ register, getValues, setValue }} name={`email_notification_ticket`} options={{ required: false }} label='E-post notiser' />
          </div>
          <div class='col mb-3'>
            <label>E-post notiser för nya kommentarer</label>
            <br />
            <CheckBox controls={{ register, getValues, setValue }} name={`email_notification_comment`} options={{ required: false }} label='E-post notiser' />
          </div>
        </div>
      </Card>
      <Modal isOpen={passwordModal} onRequestClose={() => setPasswordModal(false)} className='no-padding'>
        <Card
          title='Byt Lösenord'
          icon='fas fa-fw fa-user-lock'
          buttons={{
            header: [{ callback: () => setPasswordModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={passwordSubmit(changePassword)} text='Uppdatera lösenord' sending={sending} />]
          }}>
          <input type='hidden' {...passwordRegister('id')} value={getValues('id')} />
          <div class='row mb-3'>
            <Input
              controls={{ register: passwordRegister }}
              name='current_password'
              options={{ required: true }}
              label='Nuvarande Lösenord'
              type='password'
              error={error}
            />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register: passwordRegister }} name='password' options={{ required: true }} label='Nytt Lösenord' type='password' error={error} />
          </div>
          <div class='row'>
            <Input
              controls={{ register: passwordRegister }}
              name='password_confirmation'
              options={{ required: true }}
              label='Verifiera Lösenord'
              type='password'
              error={error}
            />
          </div>
        </Card>
      </Modal>
      <Modal isOpen={emailModal} onRequestClose={() => setEmailModal(false)} className='no-padding'>
        <Card
          title='Byt E-postadress'
          icon='fas fa-fw fa-user-lock'
          buttons={{
            header: [{ callback: () => setEmailModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={emailSubmit(changeEmail)} text='Byt e-postadress' sending={sending} />]
          }}>
          <input type='hidden' {...emailRegister('id')} value={getValues('id')} />
          <div class='row mb-3'>
            <Input controls={{ register: emailRegister }} name='email' options={{ required: true }} label='E-post' error={error} />
          </div>
          <div class='row mb-3'>
            <Input controls={{ register: emailRegister }} name='password' options={{ required: true }} label='Lösenord' type='password' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
