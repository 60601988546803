import React from 'react'
import { loader } from '@/_helpers'
import Task from '@components/layout/Task'
import ImageCard from '@components/layout/ImageCard'
import Greeting from '@components/layout/Greeting'
import { useGet } from '@enkla-gui'

export default function Onboard() {
  const { data: tasks } = useGet({ endpoint: 'api/task/onboarding' })

  if (tasks?.length === 0) return (window.location.hash = '/dashboard')

  if (!tasks) return loader.content()
  return (
    <>
      <Greeting greeting='Välkommen till Enkla Fastigheter! Få hjälp med att komma igång' />
      <div class='row'>
        <ImageCard image='onboard.svg'>
          {tasks.map(task => (
            <Task {...task} />
          ))}
        </ImageCard>
      </div>
    </>
  )
}
