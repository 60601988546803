import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { notify, date, cashmoneyhelper } from '@/_helpers'
import Modal from 'react-modal'
import DatePicker from 'react-datepicker'
import { API, ViewHeader, Table, InfoBox, Card, Output } from '@enkla-gui'
import Invoices from '@components/lease/Invoices'
import Agreements from '@components/lease/Agreements'
import Files from '@components/tools/Files'
import Addons from '@components/lease/Addons'
import EntityInfo from '@components/entity/EntityInfo'
import moment from 'moment'

export default function Lease() {
  let { id } = useParams()
  let [lease, setLease] = useState(false)
  let [modal, setModal] = useState(false)
  let [error, setError] = useState(false)
  let [sending, setSending] = useState(false)
  let [endDate, setEndDate] = useState(date.today())
  let [nextInvoiceMonth, setNextInvoiceMonth] = useState(false)
  let [invoiceMonthModal, setInvoiceMonthModal] = useState(false)
  const history = useHistory()

  useEffect(() => {
    getLease()
  }, [id])

  const getLease = () => {
    API.get(`api/lease/${id}`).then(lease => {
      setLease(lease)
      setNextInvoiceMonth(lease.next_invoice_month)
    })
  }

  const remove = async () => {
    if (!(await notify.confirm())) return
    API.delete(`api/lease/${id}`)
      .then(res => {
        notify.toast(`Uthyrning borttagen`)
        history.push(`/entity/${lease.entity_id}`)
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  const convertInterval = interval => {
    switch (interval) {
      case 'MONTHLY':
        return 'Månadsvis'
      case 'QUARTERLY':
        return 'Kvartalsvis'
      case 'YEARLY':
        return 'Årligen'
      default:
        return 'Okänt'
    }
  }
  const convertNoticeType = type => {
    switch (type) {
      case 'DAY':
        return 'Dag(ar)'
      case 'MONTH':
        return 'Månad(er)'
      case 'YEAR':
        return 'År'
      default:
        return 'Okänt'
    }
  }

  const patch = (key, value) => {
    lease[key] = value
    setSending(true)
    API.patch('api/lease', lease)
      .then(res => {
        notify.toast(`Uthyrningen har uppdaterats`)
        getLease()
        setModal(false)
        setInvoiceMonthModal(false)
        setSending(false)
      })
      .catch(([err, res]) => {
        setError({
          message: res.message,
          data: res.data
        })
        notify.toast(res.message, 'error')
        setSending(false)
      })
  }
  const archive = async () => {
    var confirmed = await notify.confirm('')
    if (!confirmed) return
    API.post(`api/lease/${lease.id}/archive`)
      .then(res => {
        notify.toast(`Uthyrningen har arkiverats`)
        history.push(`/entity/${lease.entity_id}`)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
      })
  }
  const getNextInvoicePeriod = () => {
    const date = moment(lease.next_invoice_month)
    let start = moment(lease.next_invoice_month).locale('sv').format('MMMM YYYY')
    let interval = 0
    switch (lease.payment_interval) {
      case 'QUARTERLY':
        interval = 2
        break
      case 'YEARLY':
        interval = 11
    }
    if (interval === 0) return start
    const end = date.add(interval, 'months')
    return start + ' - ' + moment(end).locale('sv').format('MMMM YYYY')
  }
  return (
    <>
      <ViewHeader
        loading={!lease}
        icon='fa-key'
        title={`Uthyrning · ${lease?.entity?.name}`}
        subtitle={lease?.entity?.type.name}
        return={{ link: `#!/entity/${lease?.entity?.id}`, title: lease?.entity?.name }}
        badges={[{ bg: lease?.state?.color, text: lease?.state?.description || lease?.state?.title, icon: lease?.state?.icon }]}
        buttons={[
          { icon: 'fa-calendar-times', title: 'Avsluta Uthyrning', callback: () => setModal(true), hidden: lease.stop !== null },
          { icon: 'fa-archive', title: 'Arkivera ', callback: () => archive(), hidden: lease.stop === null || lease.stop > date.now() },
          {
            icon: 'fa-calendar',
            title: 'Justera nästa hyresaviperiod',
            callback: () => setInvoiceMonthModal(true)
          },
          { icon: 'fa-envelope', title: 'Kontakta hyresgäster', href: `#!/contact/lease/${lease.id}` },
          { icon: 'fa-trash-alt', title: 'Radera', callback: () => remove(), position: 'right' },
          { icon: 'fa-edit', title: 'Redigera', href: `#!/lease/${lease.id}/edit`, position: 'right' }
        ]}
      />
      <div class='row'>
        <InfoBox
          loading={!lease}
          title='Uthyrt'
          subtitle={lease?.stop === null ? date.short(lease?.start) + ' - Tillsvidare' : date.short(lease?.start) + ' - ' + date.short(lease?.stop)}
          icon={lease?.state?.icon}
        />
        <InfoBox
          loading={!lease}
          title='Nästa hyresavi avser period'
          subtitle={getNextInvoicePeriod()}
          icon='fa-file-invoice'
          explination='Detta används vid automatiskt uppskapning av hyresavier'
        />
        <InfoBox
          loading={!lease}
          title='Avgift'
          subtitle={`${cashmoneyhelper.format(lease?.total)} ${convertInterval(lease?.payment_interval).toLowerCase()}`}
          icon='fa-dollar-sign'
          explination='Total avgift räknat med eventuella tillägg och avdrag'
        />
      </div>
      <Card title='Hyresgäster' icon='fas fa-fw fa-address-card'>
        <Table rows={lease.tenants} link={`/#!/tenant/@{id}`} keys={['name', 'identifier', 'phone', 'email']}>
          <th>Namn</th>
          <th>Identifikation</th>
          <th>Telefon</th>
          <th>Epost</th>
        </Table>
      </Card>
      <Card title='Uthyrning' icon='fas fa-key' loading={!lease}>
        <div class='row mb-3'>
          <Output value={lease.reference} label='Avtalsreferens' />
          <Output value={convertInterval(lease.payment_interval)} label='Betalningsintervall' />
        </div>
        <div class='row mb-3'>
          <Output value={cashmoneyhelper.format(lease.fee)} label='Avgift' />
          <Output value={lease.tax_rate + '%'} label='Momssats(%)' />
        </div>
        <div class='row mb-3'>
          <Output value={date.short(lease.start)} label='Tillträde' />
          <Output value={!lease.stop ? 'Tillsvidare' : date.short(lease.stop)} label='Slutdatum' />
        </div>
        <div class='row mb-3'>
          <Output value={lease.notice_length} label='Uppsägningstid' />
          <Output value={convertNoticeType(lease.notice_type)} label='Enhet' />
        </div>
        <div class='row mb-3'>
          <Output value={date.full(lease.created_at)} label='Skapad' />
          <Output value={date.full(lease.updated_at)} label='Uppdaterad' />
        </div>
      </Card>
      <Agreements id={lease?.id} />
      <Invoices lease={lease} />
      <EntityInfo entity={lease.entity} />
      <Files id={lease.id} type='lease' />
      <Addons id={lease.id} addons={lease.addons} callback={() => getLease()} />
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Avsluta Uthyrning'
          icon='fas fa-fw fa-calendar-times'
          buttons={{
            header: [{ callback: () => setModal(false), text: 'Avbryt', bg: 'light' }],
            footer: [{ callback: () => patch('stop', endDate), text: 'Avsluta' }]
          }}>
          <div class='mb-3 form-group col'>
            <label>Slutdatum</label>
            <DatePicker
              className='form-control'
              placeholderText='Tillsvidare'
              dateFormat='yyyy-MM-dd'
              value={date.short(endDate)}
              onChange={_date => setEndDate(date.fixTimezone(_date) / 1000)}
            />
            {error && error.data.stop && (
              <div class='form-inline-error'>
                <i class='fa fa-fw fa-exclamation-triangle' />
                {error.data.stop[0]}
              </div>
            )}
          </div>
        </Card>
      </Modal>
      <Modal isOpen={invoiceMonthModal} onRequestClose={() => setInvoiceMonthModal(false)} className='no-padding'>
        <Card
          title='Justera nästa hyresaviperiod'
          icon='fas fa-fw fa-calendar-times'
          buttons={{
            header: [{ callback: () => setInvoiceMonthModal(false), text: 'Avbryt', bg: 'light' }],
            footer: [{ callback: () => patch('next_invoice_month', nextInvoiceMonth), text: 'Spara', sending: sending }]
          }}>
          <div class='mb-3 form-group col'>
            <label>Period börjar</label>
            <DatePicker
              className='form-control'
              placeholderText='Tillsvidare'
              dateFormat='yyyy-MM'
              showMonthYearPicker
              showFullMonthYearPicker
              value={nextInvoiceMonth}
              onChange={_date => setNextInvoiceMonth(date.month(_date))}
            />
            {error && error.data.next_invoice_month && (
              <div class='form-inline-error'>
                <i class='fa fa-fw fa-exclamation-triangle' />
                {error.data.next_invoice_month[0]}
              </div>
            )}
          </div>
        </Card>
      </Modal>
    </>
  )
}
