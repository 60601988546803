import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'
import { notify } from '@/_helpers'
import { API, Card, Submit, Select, Help } from '@enkla-gui'

export default function FasdokModal({ id, isOpen, close }) {
  const { register, handleSubmit, watch, setValue } = useForm()
  const [templates, setTemplates] = useState([])
  const [organizations, setOrganizations] = useState([])
  const [integrationIsInactive, setIntegrationIsInactive] = useState(null)
  const [sending, setSending] = useState(false)

  useEffect(() => {
    if (!isOpen) return
    const init = async () => {
      var passed = await getOrganizations()
      if (!passed) return setIntegrationIsInactive(true)
      await getTemplates()
      setIntegrationIsInactive(false)
    }
    init()
  }, [isOpen])

  const getOrganizations = async () => {
    return await API.get(`api/integration/fasdok/organizations`)
      .then(res => {
        setOrganizations(res)
        return true
      })
      .catch(async ([err, res]) => {
        if (err.status === 424) return false
        notify.toast(res.message, 'error')
      })
  }

  const getTemplates = async () => {
    await API.get(`api/integration/fasdok/${id}/templates`)
      .then(res => {
        setTemplates(res)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
      })
  }

  const create = async data => {
    setSending(true)
    await API.post(`api/integration/fasdok`, {
      ...data,
      activeVersionNumber: templates.filter(t => t.id === parseInt(data.template_id))[0].activeVersionNumber
    })
      .then(res => {
        notify.toast('Ärendet har skapats som utkast hos Fastighetsägarna')
        setSending(false)
        close(true)
      })
      .catch(([err, res]) => {
        notify.toast(res.message, 'error')
        setSending(false)
      })
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={() => close()} className='no-padding'>
      <Card
        loading={integrationIsInactive === null}
        title='Skapa ärende i Fastighetsägarna Dokument'
        icon='fas fa-fw fa-gavel'
        buttons={{
          header: [{ callback: () => close(), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
          footer: [<Submit onSubmit={handleSubmit(create)} text='Skapa' sending={sending} disabled={integrationIsInactive} />]
        }}>
        {integrationIsInactive && (
          <Help temporary header={false} type='danger'>
            <p>Du har inte anslutit Fastighetsägarna Dokument.</p>
            <p>
              För att ansluta gå till <a href='#/setting/integration'>inställningar</a>
            </p>
          </Help>
        )}
        {!integrationIsInactive && (
          <>
            <Help title='Information' type='info'>
              <p>Ett ärende kommer att skapas upp som utkast i Fastighetsägarna Dokument utifrån den mall du väljer</p>
              <p>
                Saknar du en mall? <a href='#!/help/contact'>Kontakta oss</a> så löser vi det.
              </p>
            </Help>
            <div class='row mb-3'>
              <input type='hidden' {...register('lease_id')} value={id} />
              <Select
                noInitialValue
                controls={{ register }}
                name='organization_id'
                options={{ required: true }}
                label='Organisation (Fastighetsägarna Dokument)'
                values={organizations.map(organization => {
                  return {
                    key: organization.name,
                    value: organization.id
                  }
                })}
              />
            </div>
            <div class='row mb-3'>
              <Select
                controls={{ register, setValue }}
                name='template_id'
                options={{ required: true }}
                label='Mall'
                values={templates.map(template => {
                  return {
                    key: template.name,
                    value: template.id
                  }
                })}
              />
            </div>
            <div class='row mb-3'>
              <div class='form-group'>
                <label>Beskrivning</label>
                <textarea
                  rows={8}
                  disabled
                  type='text'
                  style={{ background: '#f6f7f9' }}
                  class='form-control form-control-input '
                  value={templates.filter(template => template.id === parseInt(watch('template_id')))[0]?.description}
                />
              </div>
            </div>
          </>
        )}
      </Card>
    </Modal>
  )
}
