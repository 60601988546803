import React from 'react'
import { ViewHeader, Card } from '@enkla-gui'
import FAQ from '@components/help/FAQ'

export default function HelpFAQ() {
  return (
    <>
      <ViewHeader icon='fa-life-ring' title='Hjälp' subtitle='FAQ' />
      <Card>
        <h3 style={{ color: '#3F4254', fontWeight: '700' }}>Vanliga frågor</h3>
        <p class='mb-5' style={{ color: '#7E8299', fontWeight: '600', fontSize: '15px' }}>
          Här svarar vi på de vanligaste frågorna, skulle du inte hitta ett svar kan du alltid{' '}
          <a href='/#!/help/contact' class='text-decoration-none'>
            kontakta oss.
          </a>
        </p>
        <FAQ question='Hur ställer jag in automatisk hyresavisering?'>
          <p>
            Automatisk hyresavisering konfigureras på ett företag. Utgå från ett <a href='/#!/company'>företag</a> och klicka på "Inställningar för
            hyresavisering".
          </p>
          <p></p>
        </FAQ>
        <FAQ question='Hur använder jag Fortnox?'>
          <p>
            För att kunna koppla ditt Fortnox-konto krävs det att du har en integrationslicens. Mer information om detta hittar du{' '}
            <a href='https://support.fortnox.se/komigangguide-tjanster/kom-igang-med-partner-appar' target='_blank'>
              här
            </a>
          </p>
          <p>
            Efter det kan du ansluta Fortnox som <a href='#!/setting/integration'>integration.</a>
          </p>
          <p>
            När du kopplat integrationen så behöver du på ett <a href='/#!/company'>företag</a> välja att använda denna integration. Detta görs under
            "Inställningar för hyresavisering".
          </p>
        </FAQ>
        <FAQ question='Hur använder jag Visma?'>
          <p>
            Börja med att ansluta Visma som <a href='#!/setting/integration'>integration.</a>
          </p>
          <p>
            När du kopplat integrationen så behöver du på ett <a href='/#!/company'>företag</a> välja att använda denna integration. Detta görs under
            "Inställningar för hyresavisering".
          </p>
        </FAQ>
        <FAQ question='Vad har ni för uppsägningstid?'>
          <p>
            Vi har ingen uppsägningstid, vill du sluta använda systemet så räcker det med att avsluta abonnemanget så har du tillgång resten av din betalda
            period.
          </p>
        </FAQ>

        {/* <FAQ question='Hur fungerar hierarkin?'>
          <p>lite fina bilder här</p>
        </FAQ> */}
        <FAQ question='GDPR för mina hyresgäster?'>
          <p>
            I samband med att en uthyrning arkiveras raderas även hyresgästen från systemet. Har hyresgästen flera uthyrningar kommer den finnas kvar tills den
            sista försvinner. Arkivering sker automatiskt efter 30 dagar. Du kan även välja att manuellt arkivera en avslutad uthyrning.
          </p>
        </FAQ>
        <FAQ question='Kan jag betala med faktura?'>
          <p>I dagsläget stödjer vi inte fakturabetalning.</p>
        </FAQ>
        <FAQ question='Kan jag testa hyresgästportalen?'>
          <p>Självklart kan du det. Skapa ett objekt och hyr ut det till en testkund, ange en epost-adress du själv har tillgång till.</p>
          <p>När du skapat uthyrningen kommer det komma ett mail med instruktioner hur du skapar ett konto i hyresgästportalen.</p>
          <p>
            OBS! Glöm inte att <a href='/#!/setting/tenantportal'>se till att portalen är aktiverad.</a>
          </p>
        </FAQ>
      </Card>
    </>
  )
}
