import React from 'react'

export default function ImageCard ({image, children, top = false}) {
  return (
    <div class='component_ImageCard'>
      <div class='card'>
        {!top &&
          <div class='row'>
            <div class='col-md-6' style={{'padding': '50px'}}>
              <img src={`${process.env.PUBLIC_URL}/images/${image}`} style={{'maxWidth': '100%'}} alt='' />
            </div>
            <div class='col-md-6 bicflex'>
              <div class='card-body' style={{'padding': '50px'}}>
                {children}
              </div>
            </div>
          </div>
        }
        {top &&
          <>
            <div class='row'>
              <div class='col-md-12 bicflex' style={{'padding': '50px'}}>
                <img src={`${process.env.PUBLIC_URL}/images/${image}`} style={{'maxHeight': '10rem'}} alt='' />
              </div>
            </div>
            <div class='row'>
              <div class='col-md-12'>
                <div class='card-body' style={{'padding': '50px'}}>
                  {children}
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>

  )
}
