import React, { useEffect, useState } from 'react'
import { notify, integrationhelper } from '@/_helpers'
import { API, ViewHeader, Card, Table, Submit, Input, Help } from '@enkla-gui'
import { useForm } from 'react-hook-form'
import Modal from 'react-modal'

export default function Integration() {
  const { register, handleSubmit, reset } = useForm()
  const [integrations, setIntegrations] = useState(false)
  const [modal, setModal] = useState(false)
  const [fasDokModal, setFasDokModal] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(null)

  const { register: fasDokRegister, handleSubmit: fasDokSubmit } = useForm()

  const getIntegrations = () => {
    API.get('api/integration').then(integrations => setIntegrations(integrations))
  }
  useEffect(() => {
    getIntegrations()
  }, [])

  const remove = async integration => {
    if (!(await notify.confirm(`Vill du ta bort kopplingen för ${integrationhelper.name(integration.name)}?`))) return
    API.delete(`api/integration/${integration.id}`)
      .then(res => {
        notify.toast('Integration bortkopplad')
        getIntegrations()
      })
      .catch(([err, res]) => {
        notify.toast(res, 'error')
      })
  }
  const connectFasDok = data => {
    setSending(true)
    API.post(`api/integration/fasdok/connect`, data)
      .then(res => {
        notify.toast('Integration skapad')
        setFasDokModal(false)
        setSending(false)
        getIntegrations(true)
      })
      .catch(([err, res]) => {
        notify.toast(res, 'error')
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  const update = data => {
    setSending(true)
    API.patch(`api/integration`, data)
      .then(res => {
        notify.toast('Integration uppdaterad')
        setModal(false)
        setSending(false)
        getIntegrations(true)
      })
      .catch(([err, res]) => {
        notify.toast(res, 'error')
        setError({
          message: res.message,
          data: res.data
        })
        setSending(false)
      })
  }

  return (
    <>
      <ViewHeader
        icon='fa-plug'
        title='Inställningar'
        subtitle='Integrationer'
        buttons={[
          { title: 'Koppla Fortnox', icon: 'fas fa-file-invoice', callback: () => API.get('api/fortnox/login').then(res => (window.location.href = res)) },
          { title: 'Koppla Visma', icon: 'fas fa-file-invoice', callback: () => API.get('api/visma/login').then(res => (window.location.href = res)) },
          { title: 'Koppla Fastighetsägarna Dokument', icon: 'fas fa-gavel', callback: () => setFasDokModal(true) }
        ]}
      />
      <Card loading={!integrations} title='Integrationer' icon='fas fa-plug'>
        <Table
          rows={integrations}
          empty={{ text: 'Du har inte kopplat någon integration', icon: 'fas fa-server' }}
          functions={{
            name: name => <span class='badge bg-success'>{name.charAt(0).toUpperCase() + name.toLowerCase().slice(1)} </span>,
            remove: integration => remove(integration),
            set: integration => {
              setModal(true)
              reset(integration)
            }
          }}
          keys={['_this>button:set:fa-edit', 'description', 'name>function:name', 'created_at>date:full', '_this>button:remove:fa-unlink']}>
          <th class='min' />
          <th>Beskrivning</th>
          <th class='min'>Namn</th>
          <th>Kopplad</th>
          <th class='min' />
        </Table>
      </Card>
      <Modal isOpen={modal} onRequestClose={() => setModal(false)} className='no-padding'>
        <Card
          title='Redigera Integration'
          icon='fas fa-fw fa-receipt'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={handleSubmit(update)} text='Uppdatera' sending={sending} />]
          }}>
          <div class='row mb-3'>
            <Input controls={{ register }} name='description' options={{ required: true }} label='Beskrivning' error={error} />
          </div>
        </Card>
      </Modal>
      <Modal isOpen={fasDokModal} onRequestClose={() => setFasDokModal(false)} className='no-padding'>
        <Card
          title='Koppla Fastighetsägarna Dokument'
          icon='fas fa-fw fa-gavel'
          buttons={{
            header: [{ callback: () => setModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [<Submit onSubmit={fasDokSubmit(connectFasDok)} text='Koppla' sending={sending} />]
          }}>
          <div class='row'>
            <Help type='info'>
              <p>
                Om du inte vet hur du skapar en API-nyckel så finns det förklarat på{' '}
                <a target='_blank' href='https://kundo.se/org/fastighetsagarna/d/var-hittar-jag-api-nyckeln/'>
                  Fastighetsägarnas kundforum
                </a>
              </p>
            </Help>
          </div>
          <div class='row mb-3'>
            <Input controls={{ register: fasDokRegister }} name='access_token' options={{ required: true }} label='API-nyckel' error={error} />
          </div>
        </Card>
      </Modal>
    </>
  )
}
