import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { notify, tickethelper } from '@/_helpers'
import { useGet, API, ViewHeader, Table, Pagination, Card, Help } from '@enkla-gui'
import EntityLeaseStatus from '@components/lease/EntityLeaseStatus'
import Files from '@components/tools/Files'
import Events from '@components/tools/Events'
import Modal from 'react-modal'
import EntityInfo from '@components/entity/EntityInfo'
import MaintenanceTable from '@components/maintenance/MaintenanceTable'

export default function Entity() {
  const { id } = useParams()
  const { data: entity } = useGet({ endpoint: `api/entity/${id}` })
  const { data: children, setPage: fetchChildren } = useGet({ endpoint: `api/entity/${id}/children` })
  const { data: tickets, setPage: fetchTickets } = useGet({ endpoint: `api/entity/${id}/tickets` })

  let [deleteModal, setDeleteModal] = useState(false)
  let [entityInfoModal, setEntityInfoModal] = useState(false)
  const history = useHistory()

  const remove = async keepChildren => {
    if (deleteModal) setDeleteModal(false)
    if (!(await notify.confirm(entity.leases.length !== 0 ? `Detta objekt har ${entity.leases.length} uthyrning(ar)` : ''))) return
    API.delete(`api/entity/${id}`, { keepChildren: keepChildren })
      .then(res => {
        notify.toast(`${entity.name} borttagen`)
        history.push(entity.parent_id !== null ? `/entity/${entity.parent_id}` : `/company/${entity.company_id}`)
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  return (
    <>
      <ViewHeader
        loading={!entity}
        icon={entity?.type?.icon}
        title={entity?.name}
        subtitle={entity?.company?.name}
        return={{
          link: entity?.parent_id !== null ? `#!/entity/${entity?.parent_id}` : `#!/company/${entity?.company_id}`,
          title: entity?.parent_id !== null ? entity?.parent?.name : entity?.company?.name
        }}
        buttons={[
          { icon: 'fa-envelope', title: 'Kontakta hyresgäster', href: `#!/contact/entity/${entity?.id}` },
          { icon: 'fa-info', title: 'Visa objektinformation', callback: () => setEntityInfoModal(true) },
          { icon: 'fa-trash-alt', title: 'Radera', callback: () => (children.data.length !== 0 ? setDeleteModal(true) : remove()), position: 'right' },
          { icon: 'fa-edit', title: 'Redigera', href: `#!/entity/${entity?.id}/edit`, position: 'right' }
        ]}
      />
      {entity?.type?.rentable === 1 && <EntityLeaseStatus entity={entity} />}
      {entity && <MaintenanceTable id={entity.id} type='entity' />}
      {entity?.type?.level === 0 && (
        <Card
          title='Objekt'
          icon='fas fa-fw fa-layer-group'
          buttons={{
            header: [{ link: `#!/entity/create/entity/${entity.id}`, icon: 'fas fa-plus-square text-light', text: 'Skapa' }]
          }}>
          <Table
            rows={children?.data}
            link={`/#!/entity/@{id}`}
            empty={{ text: 'Det finns inte några underliggande objekt', icon: 'fas fa-layer-group' }}
            functions={{
              getType: type => (
                <div class='icon-badge'>
                  <i class={`fas fa-fw ${type.icon}`} /> <span>{type.name}</span>{' '}
                </div>
              ),
              getStatus: entity =>
                entity.type.rentable ? (
                  <span class={`w-100 badge ${entity.rented ? 'bg-success' : 'bg-light'}`}>{entity.rented ? 'Uthyrd' : 'Vakant'}</span>
                ) : (
                  ''
                )
            }}
            keys={['name', 'type>function:getType', 'directchildren_count>label:light', '_this>function:getStatus']}>
            <th class='min'>Namn</th>
            <th class='min'>Typ</th>
            <th>Underliggande Objekt</th>
            <th class='min'>Status</th>
          </Table>
          <Pagination data={children} fetch={fetchChildren} />
        </Card>
      )}
      <Card
        title='Ärenden'
        icon='fas fa-envelope-open-text'
        buttons={{
          header: [{ link: `#!/ticket/create/entity/${entity?.id}`, icon: 'fas fa-plus-square text-light', text: 'Skapa' }]
        }}>
        <Table
          rows={tickets?.data}
          link={`/#!/ticket/@{id}`}
          empty={{ text: 'Det finns inte några ärenden', icon: 'fas fa-envelope-open-text' }}
          functions={{
            getStatus: status => <span class={`badge w-100 bg-${tickethelper.status(status).color}`}>{tickethelper.status(status).label}</span>,
            tenant: data =>
              data.tenant ? (
                <a class='text-decoration-none' onClick={e => e.stopPropagation()} href={`/#!/tenant/${data.tenant.id}/`}>
                  {' '}
                  {data.tenant.name}
                </a>
              ) : (
                ''
              ),
            getType: type => <span>{tickethelper.type(type)}</span>
          }}
          keys={['status>function:getStatus', 'reference', 'type>function:getType', 'subject', 'created_at>date:full', '_this>function:tenant']}>
          <th class='min'>Status</th>
          <th class='min'>Ärendenummer</th>
          <th class='min'>Ärendetyp</th>
          <th>Ämne</th>
          <th class='min'>Skapat</th>
          <th class='min'>Hyresgäst</th>
        </Table>
        <Pagination data={tickets} fetch={fetchTickets} />
      </Card>
      <div class='row'>
        <Events id={entity?.id} type='entity' />
        <Files id={entity?.id} type='entity' />
      </div>
      <div class='row'>
        {entity?.archived_leases?.length !== 0 && (
          <Card title='Arkiverade Uthyrningar' icon='fas fa-fw fa-archive'>
            <Table rows={entity?.archived_leases} keys={['lease.reference', 'lease.fee>currency', 'lease.stop>date:short', 'created_at>date:short']}>
              <th>Referens</th>
              <th>Avgift</th>
              <th>Uppsagt</th>
              <th>Arkiverat</th>
            </Table>
          </Card>
        )}
      </div>
      <Modal isOpen={deleteModal} onRequestClose={() => setDeleteModal(false)} className='no-padding'>
        <Card
          title='Radera Objekt'
          icon='fas fa-trash-alt'
          buttons={{
            header: [{ callback: () => setDeleteModal(false), icon: 'fas fa-times', text: 'Avbryt', bg: 'light' }],
            footer: [
              { callback: () => remove(true), bg: 'light', text: 'Flytta Underliggande' },
              { callback: () => remove(false), bg: 'danger text-white', text: 'Radera Underliggande' }
            ]
          }}>
          <Help title='Varning' type='danger' icon='fas fa-exclamation-triangle'>
            <p>Du är på väg att radera ett objekt med underliggande objekt.</p>
            <p>
              Väljer du <b>Radera Underliggande</b> kommer <b>ALLA</b> underliggande objekt att raderas
            </p>
            <p>
              Väljer du <b>Flytta Underliggande</b> kommer alla underliggande objekt flyttas till{' '}
              <b>{entity?.parent ? entity?.parent?.name : entity?.company?.name}</b>
            </p>
          </Help>
        </Card>
      </Modal>
      <Modal isOpen={entityInfoModal} onRequestClose={() => setEntityInfoModal(false)} className='no-padding'>
        <EntityInfo entity={entity} />
      </Modal>
    </>
  )
}
