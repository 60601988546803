import React from 'react'
import { date, maintenancehelper, notify } from '@/_helpers'
import { useParams, useHistory } from 'react-router-dom'
import { usePost, ViewHeader, Table, Card, Button, Output, InfoBox, useGet, useDelete, API } from '@enkla-gui'
import MaintenanceTasks from '@components/maintenance/MaintenanceTasks'
import MaintenanceTasksHandler from '@components/maintenance/MaintenanceTasksHandler'
import { icons } from '@config'
import { BehaviorSubject } from 'rxjs'
import Files from '@components/tools/Files'
import Events from '@components/tools/Events'

export default function MaintenanceView() {
  let { id } = useParams()
  const history = useHistory()

  let { data: activity, fetch } = useGet({ endpoint: `api/maintenance/${id}` })
  let { post, loading } = usePost({ success: fetch, confirm: ' ' })
  let { remove } = useDelete({
    endpoint: `api/maintenance/${id}`,
    success: () => history.push('/maintenance/overview')
  })

  return (
    <>
      <ViewHeader
        loading={!activity}
        icon={icons.maintenance}
        title={'Förvaltningsaktivitet'}
        subtitle={activity?.title}
        return={{ link: '#!/maintenance/overview', title: 'Översikt' }}
        badges={[maintenancehelper.statusBadge(activity?.status)]}
        buttons={[
          {
            icon: icons.ban,
            loading: loading,
            title: 'Makulera',
            hidden: activity?.status === 'CANCELLED',
            callback: () => post({ endpoint: `api/maintenance/${id}/cancel` })
          },
          {
            icon: icons.play,
            loading: loading,
            title: 'Påbörja',
            hidden: activity?.status !== 'PLANNED',
            callback: () => post({ endpoint: `api/maintenance/${id}/start` })
          },
          {
            icon: icons.play,
            loading: loading,
            title: 'Slutför',
            color: 'success',
            hidden: activity?.status !== 'IN_PROGRESS',
            callback: () => post({ endpoint: `api/maintenance/${id}/finish` })
          },
          { icon: icons.remove, title: 'Radera', callback: remove, position: 'right' },
          { icon: icons.edit, hidden: activity?.template_id !== null, title: 'Redigera', href: `#!/maintenance/${activity?.id}/edit`, position: 'right' },
          {
            icon: icons.link,
            hidden: activity?.template_id === null,
            title: 'Visa rutin',
            href: `#!/maintenance/template/${activity?.template_id}/edit`,
            position: 'right'
          }
        ]}
      />
      <div class='row'>
        {activity?.status === 'IN_PROGRESS' && <InfoBox loading={!activity} title='Påbörjad' subtitle={date.full(activity?.started_at)} icon={icons.clock} />}
        {activity?.status === 'DONE' && <InfoBox loading={!activity} title='Avklarad' subtitle={date.full(activity?.completed_at)} icon={icons.clock} />}
        {activity?.status === 'PLANNED' && (
          <InfoBox
            loading={!activity}
            title='Schemalagd'
            subtitle={activity?.scheduled_at == null ? 'Ej schemalagd' : date.short(activity?.scheduled_at)}
            icon={icons.clock}
          />
        )}
        <InfoBox loading={!activity} title='Avklarade uppgifter' subtitle={activity?.completion} icon={icons.check} />
      </div>
      <Card loading={!activity}>
        <div class='row mb-3'>
          <Button label='Objekt' hidden={!activity?.entity?.name} value={activity?.entity?.name} link={`#!/entity/${activity?.entity?.id}`} />
          <Button label='Företag' hidden={!activity?.company?.name} value={activity?.company?.name} link={`#!/company/${activity?.company?.id}`} />
        </div>
        <div class='row'>
          <div class='col'>
            <div class='form-group'>
              <textarea
                rows={10}
                disabled
                type='text'
                style={{ background: 'rgb(248, 248, 251)' }}
                class='form-control form-control-input '
                value={activity?.description}
              />
              <label class='label-control'>Beskrivning</label>
            </div>
          </div>
        </div>
      </Card>
      {activity?.tasks && (
        <Card title='Uppgifter' icon={icons.tasks}>
          {!['IN_PROGRESS', 'DONE'].includes(activity?.status) && <MaintenanceTasks tasks={new BehaviorSubject(activity.tasks)} previewMode />}
          {['IN_PROGRESS', 'DONE'].includes(activity?.status) && <MaintenanceTasksHandler activity={activity} callback={fetch} />}
        </Card>
      )}
      <Events id={activity?.id} type='maintenance' />
      <Files id={activity?.id} type='maintenance' />
    </>
  )
}
