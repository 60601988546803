import { Card, ViewHeader, useGetAll } from '@enkla-gui'
import React, { useEffect, useState } from 'react'

import FullCalendar from '@fullcalendar/react'
import bootstrapPlugin from '@fullcalendar/bootstrap';
import dayGridPlugin from '@fullcalendar/daygrid'
import svLocale from '@fullcalendar/core/locales/sv';

export default function Calendar() {
  const { data: maintenance } = useGetAll({ endpoint: `api/maintenance` })
  const { data: leases } = useGetAll({ endpoint: `api/lease` })
	const [activities, setActitivies] = useState([])

	useEffect(() => {
		console.log("RUNNING HOOK")
		let maintenanceActivities = maintenance?.map(activity => {
			return {
				id: `${activity.id}`,
				title: `${activity.title}`,
				start: new Date(activity.scheduled_at * 1000),
				groupId: activity.status,
				url: `#!/maintenance/${activity.id}`,
				allDay: true,
				extendedProps: {
					icon: 'fa-wrench',
					linked: activity.entity.name
				}
			}}
		)
		if (!Array.isArray(maintenanceActivities)) maintenanceActivities = []
		let leaseActivities = leases?.map(lease => {
			return [{
				id: `${lease.id}`,
				title: `Inflyttning ${lease.tenants[0].name}`,
				start: new Date(lease.start * 1000),
				groupId: 'LEASE_IN',
				url: `#!/lease/${lease.id}`,
				allDay: true,
				extendedProps: {
					icon: 'fa-key',
					linked: lease.entity.name
				}
			}, {
				id: `${lease.id}`,
				title: `Utflyttning ${lease.tenants[0].name}`,
				start: new Date(lease.stop * 1000),
				groupId: 'LEASE_OUT',
				url: `#!/lease/${lease.id}`,
				allDay: true,
				extendedProps: {
					icon: 'fa-key',
					linked: lease.entity.name
				}

			}]
		})
		if (!Array.isArray(leaseActivities)) leaseActivities = []
		console.log([...maintenanceActivities, ...leaseActivities.flat()])
		setActitivies([...maintenanceActivities, ...leaseActivities.flat()])
	}, [maintenance, leases])

  return (
		<>
		<ViewHeader icon='fa-calendar' title='Kalender' subtitle='Översikt' />
    <Card>
      <div class='row'>
      <FullCalendar
				locale={svLocale}
        plugins={[dayGridPlugin, bootstrapPlugin]}
        initialView='dayGridMonth'
        weekends={true}
        events={activities}
				themeSystem='bootstrap'
        eventContent={renderEventContent}
      />
      </div>
    </Card>
		</>
  )
}
function renderEventContent(eventInfo) {
  return (
		console.log(eventInfo.event,eventInfo.event.groupId),
    <span style={{padding: '2px'}}>
		  <b><span class={`calendar-type-icon ${statusColor(eventInfo.event.groupId)}`}><i class={`fa ${eventInfo.event.extendedProps.icon}`}></i></span> {eventInfo.event.extendedProps.linked}</b>
      <p> {eventInfo.event.title}</p>
    </span>
  )
}
function statusColor(status) {
	switch (status) {
		case 'PLANNED':
      return 'bg-secondary text-white'
			case 'IN_PROGRESS':
							return 'bg-warning'
			case 'LEASE_IN':
			case 'DONE':
							return 'bg-success'
			case 'LEASE_OUT':
			case 'OVERDUE':
							return 'bg-danger'
			case 'CANCELLED':
							return 'bg-warning'
			default:
							return 'bg-warning'


	}
}
