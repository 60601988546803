const core = {
  login: 'api/auth/login',
  register: 'api/auth/register',
  refresh: 'api/auth/refresh',
	server: 'https://api.enklafastigheter.se/'
}
// const core = {
//   server: 'https://api.enklafastigheter.se/'
// }

export { core }
