import React from 'react'
import Tippy from '@tippyjs/react';
export default function Explination ({tip}) {
  return (
    <span class='component_Explination'>
        <Tippy content={tip}>
            <i class='far fa-question-circle text-muted'></i>
        </Tippy>
    </span>
  )
}
