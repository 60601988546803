import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '@/App'
import { date, notify, useInterval } from '@/_helpers'
import { authenticationService, API } from '@enkla-gui'
import { useHistory } from 'react-router-dom'

export default function User() {
  const history = useHistory()
  const [active, setActive] = useState(0)
  const [notifications, setNotifications] = useState([])
  const { user } = useContext(UserContext)

  useInterval(async () => {
    fetchNotifications()
  }, 30000)

  useEffect(() => {
    fetchNotifications()
  }, [])

  const fetchNotifications = () => {
    API.get('api/auth/notifications').then(notifications => setNotifications(notifications))
  }

  const Logout = function () {
    authenticationService.logout()
    history.push('/login')
  }
  const markAsRead = () => {
    if (active === 2 && notifications.unread) {
      API.post('api/auth/notifications').then(notifications => setNotifications(notifications))
      fetchNotifications()
    }
    setActive(active === 2 ? 0 : 2)
  }

  const clearNotification = (e, notification) => {
    e.stopPropagation()
    API.delete(`api/auth/notifications/${notification.id}`)
      .then(res => {
        fetchNotifications()
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  const remove = async () => {
    if (!(await notify.confirm())) return
    API.delete(`api/auth/notifications`)
      .then(res => {
        notify.toast('Alla notiser rensade')
        setActive(false)
        setNotifications([])
      })
      .catch(([err, res]) => {
        notify.toast(`Borttagningen misslyckades: ${res.message}`, 'error')
      })
  }

  const getSubscription = () => {
    switch (user.billable?.membership) {
      case -1:
        return <span class='badge bg-danger'>Inget medlemsskap</span>
      case -2:
        return <span class='badge bg-success'>Medlem</span>
      default:
        return <span class='badge bg-warning'>Testperiod ({user.billable?.membership} dagar kvar)</span>
    }
  }

  const type = data => {
    switch (data) {
      case 'App\\Notifications\\TenantNewComment':
        return <i class='fas fa-user-circle' />
      case 'App\\Notifications\\InvoicebaseReviewRequired':
        return <i class='far fa-eye' />
      case 'App\\Notifications\\ScheduledJobCompleted':
        return <i class='fas fa-clock' />
      case 'App\\Notifications\\TenantNewTicket':
        return <i class='fas fa-envelope-open-text' />
      default:
        return <i class='fas fa-exclamation-circle' />
    }
  }

  return (
    <ul class='navbar-nav navbar-user'>
      {!!active && <div class={'navbar-backdrop'} onClick={e => setActive(0)} />}
      <li class={`nav-item dropdown ${active === 2 ? 'active' : ''} notifications`}>
        <button class='nav-link' onClick={e => markAsRead()}>
          {notifications.unread > 0 ? (
            <span class='fa-stack has-badge' data-count={notifications.unread}>
              <i class='fa fa-bell fa-stack-1x' />
            </span>
          ) : (
            <span class='fa-stack'>
              <i class='fa fa-bell fa-stack-1x' />
            </span>
          )}
        </button>
        <ul class={`card w-auto notifications-dropdown-menu dropdown-menu ${active === 2 ? 'show' : ''}`}>
          {!notifications?.notifications?.length && (
            <li class='dropdown-item notification-item' style={{ justifyContent: 'center' }}>
              <i class='fas fa-bell' />
              <div>
                <b>
                  <span>Inga notifikationer!</span>
                </b>
              </div>
            </li>
          )}
          {notifications?.notifications?.map(notification => (
            <li
              class={`dropdown-item notification-item ${notification.read_at === null ? 'notification-unread' : ''}`}
              onClick={() => {
                markAsRead()
                if (notification.data.link === undefined) return
                window.location = notification.data.link
              }}>
              {type(notification.type)}
              <div>
                <a class='clear-notification' onClick={e => clearNotification(e, notification)}>
                  <i class='fas fa-times text' />
                </a>
                <b>
                  <span>{notification.data.title}</span>
                </b>
                <p>{notification.data.body}</p>
                <small class='text-muted'>{date.full(notification.created_at)}</small>
              </div>
            </li>
          ))}
          {notifications?.notifications?.length > 0 && (
            <li>
              <button class='dropdown-item text-center clear-notifications' onClick={() => remove()}>
                <b>Rensa Notifikationer</b>
              </button>
            </li>
          )}
        </ul>
      </li>
      <li class={`nav-item dropdown ${active === 1 ? 'active' : ''}`}>
        <button class='nav-link' onClick={e => setActive(active === 1 ? 0 : 1)}>
          <span class='fa-stack'>
            <i class='fa fa-user fa-stack-1x' />
          </span>
        </button>
        <ul class={`card user-dropdown dropdown-menu ${active === 1 ? 'show' : ''}`}>
          <li>
            <div class='user-info'>
              <span>{user.name}</span>
              <span>{user.billable?.name}</span>
              <span style={{ marginTop: '10px' }}>{getSubscription()}</span>
            </div>
          </li>
          <li>
            <hr class='dropdown-divider' />
          </li>
          <li>
            <a class='dropdown-item' href='#!/setting/user' onClick={() => setActive(false)}>
              <i class='fas fa-fw fa-user' /> Profil
            </a>
          </li>
          <li>
            <hr class='dropdown-divider' />
          </li>

          <li>
            <a class='dropdown-item' href='#!/setting/billable' onClick={() => setActive(false)}>
              <i class='fas fa-fw fa-wrench' /> Inställningar
            </a>
          </li>
          <li>
            <hr class='dropdown-divider' />
          </li>

          <li>
            <button onClick={() => Logout()} class='dropdown-item text-danger logout'>
              <i class='fas fa-sign-out-alt' /> Logga ut
            </button>
          </li>
        </ul>
      </li>
    </ul>
  )
}
